<template>
  <v-row no-gutters>
    <v-col
      cols="8"
      lg="6"
      style="padding-right:10px"
      class="d-flex flex-column align-start justify-center"
    >
      <div class="text-center mon-compte-title">
        <span v-if="appTitle">
          <span class="font-weight-bold">
            <img
              :src="require('@/assets/images/myFFME.png')"
              style="max-width:140px; max-height: 30px;"
            />
          </span>
          <span class="d-none d-md-inline">
            {{ appTitle }}
          </span>
        </span>
        <span v-else class="font-weight-bold">
          <img
            :src="require('@/assets/images/myFFME.png')"
            style="max-width:140px; max-height: 30px;"
          />
        </span>
        <span
          class="current-saison"
          v-if="appUserMode != 'federal'"
            style="color:#929292 !important"
        >
          - {{ currentSaison.NomSaison }}</span
        >
      </div>
    </v-col>

    <v-col
      cols="4"
      lg="6"
      class="d-flex align-center justify-end row no-gutters"
    >
      <v-col v-if="hasRoleFederal" cols="3" class="d-none d-lg-flex align-center justify-center mx-4">
        <v-btn
          class="appmode-btn"
          @click="toggleUserMode"
        >
          <p style="margin-right: 8px;">{{ otherSpace }}</p>
          <v-icon>mdi-swap-horizontal</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1" class="d-none d-lg-flex align-center justify-center mx-4">
        <v-menu
          rounded
          location="bottom end"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar
                color="grey"
                size="40"
              >
                <v-img
                  v-if="avatar"
                  v-bind:src="avatar.CheminDocument"
                  :alt="currentUser.CT_Prenom + ' ' + currentUser.CT_Nom"
                ></v-img>
                <span v-else class="text-h6">{{ currentUser.CT_Prenom.charAt(0) }}{{ currentUser.CT_Nom.charAt(0) }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-card-text style="padding-left: 0; padding-right: 0;">
              <div class="text-left">
                <div>
                  <v-btn
                    class="profile-btn"
                    @click="licencieUserMode"
                  >
                    Mon profil
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    class="profile-btn"
                    @click="goToHelpCenter"
                  >
                    Aide
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    class="profile-btn"
                    @click="logOut"
                  >
                    Me déconnecter
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" class="d-xl-none mx-4">
        <div class="d-flex align-center justify-end" style="height: 100%; gap: 30px;">
          <v-icon @click="toggleUserMode">mdi-swap-horizontal</v-icon>
          <v-menu
            rounded
            location="bottom end"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar
                  color="grey"
                  size="40"
                >
                  <v-img
                    v-if="avatar"
                    v-bind:src="avatar.CheminDocument"
                    :alt="currentUser.CT_Prenom + ' ' + currentUser.CT_Nom"
                  ></v-img>
                  <span v-else class="text-h6">{{ currentUser.CT_Prenom.charAt(0) }}{{ currentUser.CT_Nom.charAt(0) }}</span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-card-text style="padding-left: 0; padding-right: 0;">
                <div class="text-left">
                  <div>
                    <v-btn
                      class="profile-btn"
                      @click="licencieUserMode"
                    >
                      Mon profil
                    </v-btn>
                  </div>
                  <div>
                    <v-btn
                      class="profile-btn"
                      @click="goToHelpCenter"
                    >
                      Aide
                    </v-btn>
                  </div>
                  <div>
                    <v-btn
                      class="profile-btn"
                      @click="logOut"
                    >
                      Me déconnecter
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-app-bar-nav-icon
            @click="toggleDrawer"
            style="z-index: 9999"
          ></v-app-bar-nav-icon>
        </div>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { warning_notification, clear_notifications } from "../plugins/notifications";

import SeasonCheckAffiliationNotification from "./utils/seasonCheckAffiliationNotification.vue";
import HeaderNotification from "./utils/headerNotification.vue";
import { getLicences } from "@/plugins/licenceService";
import { waitingDocument } from "@/constants/licenceStatus";

import RoleConstants from "../constants/roles";

export default {
  name: "AppHeader",

  data: () => ({
    showContactFormButton: true,
    currentUserDatas: null,
    avatar: null,
  }),

  async created() {
    this.setRightSelectedSaison();

    if (
      (
        this.currentStructure.STR_Structure.LIST_StructureType.EST_Club
        || this.currentStructure.STR_Structure.LIST_StructureType.SlugTypeStructure === 'club_membre_associe'
      )
      && [
        RoleConstants.ClubAdministrateur,
        RoleConstants.EtablissementAffilieAdministrateur,
        RoleConstants.MembreAssocieAdministrateur
      ].includes(this.currentStructure.ID_Role)
      && !this.notificationAffiliation
    ) {
      // Warm about the new season check requirement if structure affiliation is not done
      let affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.currentSaisonId,
      });

      const affiliationNextSeason = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.nextSaison.id,
      });

      if (this.currentStructure.STR_Structure.LIST_StructureType.SlugTypeStructure !== 'club_membre_associe' && this.nextSaison.ID_EtapeSaison === 3 && affiliation && affiliation.ID_EtapeAffiliation === 3 && (!affiliationNextSeason || affiliationNextSeason.ID_EtapeAffiliation !== 3)) {
        clear_notifications();
        warning_notification(
          {
            component: SeasonCheckAffiliationNotification,
            listeners: {
              selectedSeason: () => this.nextSaison.id,
              notificationContent: () => `La saison ${this.nextSaison.id} entre dans sa période de pré-saison, ré-affilier votre structure pour préparer au mieux la saison prochaine`,
              affiliate: () => {
                if (this.currentStructure.STR_Structure.LIST_StructureType.SlugTypeStructure !== 'club_membre_associe') {
                  this.$router.push({ name: "affiliations__informations_generales", params: { seasonId: this.nextSaison.id } })
                } else {
                  this.$router.push({ name: "information__historique_affiliations" })
                }
              },
              onClose: () => {
                this.setNotificationAffiliation(true);
              },
            }
          },
          {
            position: 'top-center',
            timeout: false,
            closeButton: false,
            closeOnClick: false,
            draggable: true,
            draggablePercent: 0.6,
          }
        );

        return;
      }

      if (!affiliation || affiliation.ID_EtapeAffiliation !== 3) {
        clear_notifications();
        warning_notification(
          {
            component: SeasonCheckAffiliationNotification,
            listeners: {
              selectedSeason: () => this.currentSaison.id,
              notificationContent: () => `La saison ${this.currentSaison.id} vient de démarrer, ré-affilier votre structure pour profiter pleinement de la plateforme`,
              affiliate: () => {
                if (this.currentStructure.STR_Structure.LIST_StructureType.SlugTypeStructure !== 'club_membre_associe') {
                  this.$router.push({ name: "affiliations__informations_generales", params: { seasonId: this.currentSaison.id } })
                } else {
                  this.$router.push({ name: "information__historique_affiliations" })
                }
              },
              onClose: () => {
                this.setNotificationAffiliation(true);
              },
            }
          },
          {
            position: 'top-center',
            timeout: false,
            closeButton: false,
            closeOnClick: false,
            draggable: true,
            draggablePercent: 0.6,
          }
        );
      }
    }

    if (this.appUserMode === 'licencie') {
      const licences = await getLicences({
        user: this.currentUser.id,
        season: this.currentSaison.id,
        active: true,
      });

      if (licences.length !== 0) {
        const licence = licences.pop();

        if (licence.status === waitingDocument && licence.product.slug !== 'licence_decouverte') {
          clear_notifications();
          warning_notification(
            {
              component: HeaderNotification,
              listeners: {
                notificationTitle: () => `Document de santé`,
                notificationContent: () => `Pour activer votre licence FFME de la ${this.currentSaison.NomSaison.toLowerCase()}, merci de mettre à jour vos données de santé dans la rubrique MON COMPTE > CERTIFICATS MEDICAUX`,
                buttonTitle: () => `Activer ma licence`,
                buttonClick: () => this.$router.push({ name: "mon_compte__certificat_medical" }),
                onClose: () => {
                  this.setNotificationLicenceDocument(true);
                },
              }
            },
            {
              position: 'top-center',
              timeout: false,
              closeButton: false,
              closeOnClick: false,
              draggable: true,
              draggablePercent: 0.6,
            }
          );
        }
      }
    }

    let avatars = await this.getUtilisateurAvatar({
      ID_Utilisateur: this.currentUser.id,
    });
    this.avatar = avatars[0] ?? null;
  },

  methods: {
    ...mapActions("app", ["toggleDrawer", "updateAppUserMode"]),
    ...mapActions("saisons", ["setSelectedSaison"]),
    ...mapActions("structure", ["getStructureAffiliation"]),
    ...mapActions('config', [
      'setNotificationAffiliation',
      'resetNotificationAffiliation',
      'setNotificationLicenceDocument',
      'resetNotificationLicenceDocument',
    ]),
    ...mapActions("utilisateurs", ["getUtilisateurAvatar"]),

    toggleUserMode() {
      clear_notifications();
      if (this.appUserMode === "federal") {
        return this.updateAppUserMode("licencie");
      }

      return this.updateAppUserMode("federal");
    },
    licencieUserMode() {
      return this.updateAppUserMode("licencie");
    },
    goToContactForm() {
      this.$router.replace({ name: "contact" });
    },

    goToHelpCenter() {
      window.open("https://aide.ffme.site/accueil/myffme/");
    },
    toggleSaison() {
      if (!this.nextSaison) return;

      if (
        this.currentSaison.id == this.selectedSaison.id &&
        ((this.nextSaison.ID_EtapeSaison === 1 && this.isFFME) ||
          (this.nextSaison.ID_EtapeSaison === 2 &&
            (this.isFFME || this.isCT)) ||
          (this.nextSaison.ID_EtapeSaison === 3 &&
            (this.isFFME || this.isCT || this.isLigue)) ||
          this.nextSaison.ID_EtapeSaison === 4)
      ) {
        this.setSelectedSaison({ ID_Saison: this.nextSaison.id });
      } else {
        this.setSelectedSaison({ ID_Saison: this.currentSaison.id });
      }
    },

    logOut() {
      if (this.$route.hash) {
        this.$router.hash = "";
        this.$router.replace({ path: "/" });
      }

      localStorage.removeItem(process.env.VUE_APP_AUTH_TOKEN_NAME);
      this.resetNotificationAffiliation();
      this.resetNotificationLicenceDocument();
      window.location = process.env.VUE_APP_FRONT_NEXT_URL + "/authentification/deconnexion";
      return;
    },
    setRightSelectedSaison() {
      if (
        !(this.currentSaison.id == this.selectedSaison.id) &&
        !(this.nextSaison.ID_EtapeSaison === 1 && this.isFFME) &&
        !(this.nextSaison.ID_EtapeSaison === 2 && (this.isFFME || this.isCT)) &&
        !(
          this.nextSaison.ID_EtapeSaison === 3 &&
          (this.isFFME || this.isCT || this.isLigue)
        ) &&
        !(this.nextSaison.ID_EtapeSaison === 4)
      ) {
        this.setSelectedSaison({ ID_Saison: this.currentSaison.id });
      }
    },
  },

  computed: {
    ...mapGetters("app", ["appUserMode", "appTitle"]),
    ...mapGetters("user", ["hasRoleFederal", "currentUser"]),
    ...mapGetters("structure", ["currentStructureId", "currentStructure"]),
    ...mapGetters("saisons", [
      "currentSaison",
      "currentSaisonId",
      "selectedSaison",
      "getSaison",
      "nextSaison",
    ]),
    ...mapGetters('config', ['notificationAffiliation']),

    otherSpace() {
      // add flag in user to know if we are in mode licencié or federal
      if (this.appUserMode === 'federal') {
        return "Espace personnel";
      }

      return "Espace fédéral";
    },

    otherSaison() {
      return this.selectedSaison.NomSaison;
    },

    canChangeSaison() {
      if (this.appUserMode === "federal") return true;
      return false;
    },
    isClub() {
      return this.currentStructure.STR_Structure.LIST_StructureType.EST_Club;
    },
    isFFME() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "service_federal_ffme";
    },
    isCT() {
      return (
        this.currentStructure.STR_Structure.LIST_StructureType
          .SlugTypeStructure == "comite_territorial"
      );
    },

    isLigue() {
      return (
        this.currentStructure.STR_Structure.LIST_StructureType
          .SlugTypeStructure == "ligue"
      );
    },
  },
};
</script>

<style lang="scss">
.Vue-Toastification__toast--warning{
  color: #5f2b01;
  background-color: #fdf0e6;

  .Vue-Toastification__close-button {
    color: #5f2b01 !important;
  }
}
.icon-bordered {
  border: 1px solid lightgray;
  border-radius: 20px;
}

.mon-compte-title {
  font-family: "Roboto" !important;
  font-weight: bold;
  font-size: 16px;
  color: black;
  text-transform: uppercase;
}

.current-saison {
  font-family: "Roboto" !important;
  font-weight: bold;
  font-size: 16px;
  color: #929292;
  text-transform: uppercase;

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.profile-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: transparent !important;
  height: auto !important;
  width: 100% !important;
  box-shadow: none !important;
  text-transform: none !important;

  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 0;
  font-weight: 500;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-size: 16px;
  -webkit-padding-start: 16px;
  padding-inline-start: 16px;
  -webkit-padding-end: 16px;
  padding-inline-end: 16px;
  margin: 0px;
  color: black;
  text-align: left;
  letter-spacing: normal !important;
}

.appmode-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: auto !important;
  width: fit-content !important;
  padding: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  text-transform: none !important;
  letter-spacing: normal !important;

  border: 1px solid rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12) !important;

  p {
    margin: 0;
    padding: 8px;
  }

  i {
    padding: 8px;
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.26);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    &::before {
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
</style>
