<template>
  <ul class="pl-0">
    <li class="nav-icon __home">
      <font-awesome-icon icon="home" />
      <router-link :to="{ name: 'Home' }">Accueil</router-link>

      <ul style="padding-left: 18px; padding-right: 6px; padding-bottom: 10px;">
        <!-- <li>
          <span class="d-flex align-center">
            <avatar
              :context="{ ID_Structure: currentStructure.STR_Structure.id }"
              size="32"
            />
            <span style="font-size: 0.8rem; padding-left: 10px;">
              {{ currentStructure.STR_Structure.NomStructure }}</span
            >
          </span>
        </li> -->

        <li
          class="font-weight-thin my-2"
          v-for="(structure, index) in withRoleFederal"
          :key="index"
        >
          <span
            :class="
              selectedStructure === structure.STR_Structure.id
                ? 'selected-structure'
                : ''
            "
            class="d-flex align-center"
          >
            <avatar
              :context="{ ID_Structure: structure.ID_Structure }"
              size="32"
            />
            <span
              @click="changeCurrentSructure(structure, index)"
              style="font-size: 0.8rem; padding-left: 19px;"
              >{{
                structure.STR_Structure
                  ? structure.STR_Structure.NomStructure
                  : "Indisponible"
              }}</span
            >
          </span>
        </li>
      </ul>
    </li>
    <li v-if="(isClub || isCT || isLigue || isFFME) && !isMembreAssocie" class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/Licence.svg"
          alt=""
      /></i>
      <a :href="linkGestionLicences">
        Gestion des licences
      </a>
    </li>

    <!-- <li v-if="isFFME" class="nav-icon __club">
      <i><img class="svg-inline--fa" src="@/assets/images/nav/new/Licence.svg" alt=""></i>
      <router-link :to="{ name: 'gestion_des_licences_siege__dashboard' }">Licences Siège</router-link>
    </li> -->

    <li
      v-if="isClub || isCT || isLigue || isMembreAssocie"
      class="nav-icon __club"
    >
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/comptabilité.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'gestion_comptabilite__dashboard' }"
        >Gestion comptabilité</router-link
      >
    </li>

    <li class="nav-icon __club" v-if="!isMembreAssocie">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/Utilisateur.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'gestion_des_utilisateurs__dashboard' }"
        >Gestion des adhérents</router-link
      >
    </li>

    <!--
    <li v-if="isFFME" class="nav-icon __club">
      <i><img class="svg-inline--fa" src="@/assets/images/nav/competition.svg" alt=""></i>
      <router-link :to="{ name: 'gestion_des_competitions__dashboard' }">Gestion compétitions</router-link>
    </li> -->

    <li v-if="isFFME" class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/comptabilité.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'gestion_des_finances__dashboard' }"
        >Finances</router-link
      >
    </li>

    <li class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/gestion_structure.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'gestion_de_la_structure__dashboard' }">{{
        gestionName
      }}</router-link>
    </li>

    <!-- <li v-if="!isClub" class="nav-icon __club">
      <i><img class="svg-inline--fa" src="@/assets/images/nav/new/gestion_des_saisons.svg" alt=""></i>
      <router-link :to="{ name: 'gestion_des_saisons__informations_generales' }">Gestion de la saison</router-link>
    </li> -->

    <li v-if="!isClub && !isMembreAssocie" class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/structure.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'gestion_des_structures' }"
        >Structures</router-link
      >
    </li>

    <li v-if="isAdmin" class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/structure.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'gestion_des_parametres_globaux' }"
        >Administration</router-link
      >
    </li>

    <li v-if="canGestionSaison" class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/season.svg"
          alt=""
      /></i>
      <a :href="linkGestionSaison"
        >Gestion de la saison</a
      >
    </li>

    <!-- <li v-if="isFFME" class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/structure.svg"
          alt=""
      /></i>
      <router-link
        :to="{
          name: 'gestion_des_animations_locale__liste_licences_hors_club',
        }"
        >Licences hors club</router-link
      >
    </li> -->

    <!-- <li v-if="canAccessPrimo" class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/structure.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'gestion_des_affiliations__dashboard' }"
        >Primo affiliations</router-link
      >
    </li> -->
  </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import avatar from "@/components/Common/Common__Avatar.vue";

import RoleConstants from "@/constants/roles";

export default {
  data() {
    return {
      selectedStructure: null,
      idRoleAdmin: 1,
      idTypeStructure: 6,
    };
  },
  created() {
    this.selectedStructure = this.currentStructureId;
  },

  components: { avatar },

  computed: {
    ...mapGetters("structure", [
      "withRoleFederal",
      "currentStructureId",
      "currentStructure",
      "currentStructureAvatar",
      "currentStructureType",
    ]),
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("user", ["currentUser", "getRole"]),

    // otherStructure() {
    //   return this.withRoleFederal.filter(
    //     (structure) => structure.ID_Structure !== this.currentStructureId
    //   );
    // },

    gestionName() {
      if (this.currentStructureType) {
        if (this.currentStructureType.EST_Club) return "Gestion club";
        return "Gestion " + this.currentStructureType.NomTypeStructure;
      } else {
        return "Gestion de la structure";
      }
    },
    linkGestionLicences() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
    linkGestionSaison() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-saison?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
    linkCarnetFormation() {
      return "https://licencie.ffme.fr/mes-formations";
    },
    getAvatarStructureUrl() {
      if (this.currentStructureAvatar) {
        return this.currentStructureAvatar.CheminDocument;
      }
      return (
        "https://ui-avatars.com/api/?name=" +
        this.currentStructure.NomStructure +
        "&size=256"
      );
    },

    isClub() {
      return this.currentStructure.STR_Structure.LIST_StructureType.EST_Club;
    },
    isMembreAssocie() {
      if (
        this.currentStructure?.STR_Structure?.LIST_StructureType?.id ===
        this.idTypeStructure
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFFME() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "service_federal_ffme";
    },
    isCT() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "comite_territorial";
    },
    isLigue() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "ligue";
    },
    isAdmin() {
      if (this.getRole?.id === this.idRoleAdmin) {
        return true;
      }
      return false;
    },
    canGestionSaison() {
      if (
        [
          RoleConstants.ClubAdministrateur,
          RoleConstants.LigueAdministrateur,
          RoleConstants.Administrateur,
          RoleConstants.EtablissementAffilieAdministrateur,
          RoleConstants.AdministrateurVieTerritoriale,
          RoleConstants.CTAdministrateur,
        ].includes(this.getRole?.id ?? 0)
      ) {
        return true;
      }

      return false;
    },
    canAccessPrimo() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return (
        type.SlugTypeStructure == "service_federal_ffme" ||
        type.SlugTypeStructure == "comite_territorial"
      );
    },
  },

  methods: {
    ...mapActions("structure", ["setCurrentStrucureFederal"]),

    changeCurrentSructure(structure) {
      this.selectedStructure = structure.STR_Structure?.id;
      this.setCurrentStrucureFederal(structure.STR_Structure?.id);
    },

    getAvatarStrucutre(structure) {
      if (structure.STR_Structure.DOC_Documents) {
        let categorie_id = this.getList.LIST_DocumentType.find(
          (doc) => doc.SlugTypeDocument == "avatar_structure"
        )?.id;
        let doc = structure.STR_Structure.DOC_Documents.filter(
          (doc) => doc.ID_Type_Document === categorie_id
        )[0];
        if (doc && doc.CheminDocument) return doc.CheminDocument;
      }

      return (
        "https://ui-avatars.com/api/?name=" +
        structure.STR_Structure.NomStructure +
        "&size=256"
      );
    },
  },
};
</script>

<style lang="scss">
.selected-structure {
  font-weight: bold;
}
</style>
