<template>
  <ul class="pl-0" v-if="mounted">
    <li class="nav-icon __home">
      <font-awesome-icon icon="home" />
      <router-link to="/">Accueil</router-link>
    </li>

    <li class="nav-icon __comptabilite">
      <font-awesome-icon icon="user" />
      <router-link :to="{ name: 'mon_compte__modification_cordonnees' }"
        >Mon compte</router-link
      >
    </li>

    <li v-if="hasALicence" class="nav-icon __comptabilite">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/Licence.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'mes_licences__ma_licence' }"
        >Ma licence</router-link
      >
    </li>

    <li v-if="currentActiveLicence && currentActiveLicence.product.slug !== 'licence_decouverte'" class="nav-icon __comptabilite">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/new/assurance.svg"
          alt=""
      /></i>
      <router-link :to="{ name: 'mes_assurances__dashboard' }"
        >Assurances</router-link
      >
    </li>

    <li class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/people.svg"
          alt=""
      /></i>
      <a :href="linkLicenseeAdvantages"
        >Avantages licenciés</a
      >
    </li>

    <li class="nav-icon __club">
      <i
        ><img
          class="svg-inline--fa"
          src="@/assets/images/nav/carnetFormation.svg"
          alt=""
      /></i>
      <a :href="linkCarnetFormation"
        >Carnet de formation</a
      >
    </li>

    <!-- <li v-if='currentStructureId' class="nav-icon __formation">
               <font-awesome-icon icon="address-card" />
               <router-link event="" to="/mon-licence">Licence</router-link>
            </li>

            <li v-if='currentStructureId' class="nav-icon __formation">
               <i><img class="svg-inline--fa" src="@/assets/images/nav/new/formation.svg" alt=""></i>
               <router-link event="" to="/formations">Formations</router-link>
            </li>

            <li v-if='currentStructureId' class="nav-icon __competition">
               <i><img class="svg-inline--fa" src="@/assets/images/nav/new/label.svg" alt=""></i>
               <router-link event="" to="/competitions">Compétitions</router-link>
            </li>

            <li v-if='currentStructureId' class="nav-icon __subventions">
               <i><img class="svg-inline--fa" src="@/assets/images/nav/new/subvention.svg" alt=""></i>
               <router-link event="" to="/mon-club">Mon club</router-link>
            </li>

            <li v-if='currentStructureId' class="nav-icon __labels">
               <font-awesome-icon icon="folder-open" />
               <router-link event="" to="/assurance">Assurance</router-link>
            </li>

            <li class="nav-icon __sites">
               <i><img class="svg-inline--fa" src="@/assets/images/nav/new/site_pratique.svg" alt=""></i>
               <router-link event="" to="/sites-pratiques">Sites pratiques</router-link>
            </li>

            <li class="nav-icon __sites">
               <font-awesome-icon icon="envelope" />
               <router-link event="" to="/contact">Contact</router-link>
            </li> -->
  </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    mounted: false,
    licences: null,
  }),

  async created() {
    await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId", "structures"]),
    ...mapGetters("saisons", ["getSaison"]),

    hasALicence() {
      return this.structures.find((s) => s.ID_LastLicence);
    },

    linkLicenseeAdvantages() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/avantages-licencie?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },

    linkCarnetFormation() {
      return `https://licencie.ffme.fr/mes-formations`;
    },

    currentActiveLicence() {
      return this.licences.find(
        (lic) => lic.season.id === this.context.ID_Saison && new Date(lic.dateEnd) > new Date()
      );
    },

    currentSaison() {
      return this.getSaison(this.context.ID_Saison);
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["getUserBasicInfos", "getLicenceUtilisateur"]),

    async setData() {
      if (this.context.UTI_Utilisateur) {
        this.currentUser = this.context.UTI_Utilisateur;
      } else {
        let user = await this.getUserBasicInfos(this.context.ID_Utilisateur);
        this.currentUser = user.UTI_Utilisateurs[0];
      }

      this.licences = await this.getLicenceUtilisateur({
        user: this.currentUser.id,
        season: this.currentSaison.id,
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      });
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
