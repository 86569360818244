<template>
  <div class="notification">
    <div class="notification__container">
      <div class="notification__header">
        <p class="notification__title">{{ notificationTitle }}</p>
        <button @click.stop="() => $emit('buttonClick')" class="notification__button">{{ buttonTitle }}</button>
      </div>

      <p class="notification__content">{{ notificationContent }}</p>
    </div>

    <button aria-label="close" @click="onClose" class="Vue-Toastification__close-button"> × </button>
  </div>
</template>


<script>

export default {
  name: "headerNotification",

  computed: {
    notificationTitle() {
      return this.$listeners.notificationTitle();
    },
    buttonTitle() {
      return this.$listeners.buttonTitle();
    },
    notificationContent() {
      return this.$listeners.notificationContent();
    },
  },

  methods: {
    onClose() {
      this.$listeners.onClose();
      this.$emit('close-toast');
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  &__title {
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }

  &__button {
    padding: 3px 8px;
    border-radius: 4px;
    border: 1px solid #5f2b01;
    text-transform: uppercase;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: rgba(95, 43, 1, 0.1);
    }
  }

  &__content {
    margin-bottom: 0;
    font-size: 1rem;
  }
}
</style>
