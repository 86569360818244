<template>
  <v-app :key="componentAppKey">
    <v-navigation-drawer app v-model="drawer.active" :color="drawer.color">
      <Navigation> </Navigation>
    </v-navigation-drawer>

    <div>
      <AppHeader class="app__header" />
      <div class="content">
        <!-- TODO: Put some transition here -->
        <router-view></router-view>
      </div>
    </div>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation/layout.vue";
import AppHeader from "@/components/AppHeader.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Navigation,
    AppHeader,
  },

  mounted() {
    // console.log(this.$vuetify.breakpoint.md)
  },

  created(){

  },

  computed: {
    ...mapGetters("app", ["drawer", "appTitle", "componentAppKey"]),
  },
};
</script>

<style>
.app__header {
  z-index: 5;
  position: relative;
  justify-content: space-between;
  padding: 12px 24px;
  box-shadow: 0px 12px 29px -12px rgba(0, 0, 0, 0.25);
  border-bottom: solid 0.5px #f7f7f7;
}

.content {
  padding-left: 0 !important;
  max-height: 100%;
  height: calc(100vh - 81px);
  overflow: auto;
}
div.content div.fullHeight {
  height: 100%;
}
</style>
