<template>
  <div class="app__nav-content m-0 p-0">
    <div cols="12" class="p-0">
      <div class="nav__logo"></div>
    </div>

    <nav class="nav-main text-white">
      <navRoleFederal v-if="hasRoleFederalNav"></navRoleFederal>
      <navLicencie v-else 
      :context="{
        ID_Saison: currentSaison.id,
        ID_Utilisateur: currentUserId,
      }"
      ></navLicencie>
    </nav>

    <div cols="12" class="nav-footer text-white mt-5 my-4 px-4">
      <div class="my-4">
        <a class="text-white font-weight-bold" href="www.myffme.fr"
          >www.myffme.fr</a
        >
        est un site de la Fédération Française de la Montagne et de l'Escalade
      </div>
      <div class="mt-4 text-white">
        <span class="font-weight-bold">
          Informations légales
        </span>
        <ul class="p-0">
          <li>
            <router-link
              class="text-white"
              :to="{ name: 'legal', params: { type: 'mentions-legales' } }"
              >Mentions legales</router-link
            >
          </li>
          <li>
            <router-link
              v-if="hasRoleFederalNav"
              class="text-white"
              :to="{ name: 'legal', params: { type: 'cgu-structures' } }"
              >CGU Structures</router-link
            >
          </li>
          <li>
            <router-link
              class="text-white"
              :to="{ name: 'legal', params: { type: 'cgu-licences' } }"
              >CGU Utilisateurs
            </router-link>
          </li>
          <li>
            <router-link
              class="text-white"
              :to="{ name: 'legal', params: { type: 'confidentialite' } }"
              >Politique de confidentialité</router-link
            >
          </li>
        </ul>
      </div>
      <div class="">
        <p>
          Partenaire de
          <a
            target="_blanck"
            href=" https://www.lemonway.com/"
            title="Lemon Way - &Eacute;tablissement de paiement agr&eacute;&eacute;"
            ><img
              style="vertical-align: middle; width: 20px"
              src="@/assets/images/nav/Lemon_Way_Icon_200px.png"/></a
          ><br />
          &Eacute;tablissement de paiement agr&eacute;&eacute; par l'ACPR en
          France (CIB 16568).
        </p>
      </div>
      <div class="mb-0 mt-4">
        © FFME {{ currentYear }} - Reproduction interdite -
        <a href="https://www.ffme.fr" target="_blanck">www.ffme.fr</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import navRoleFederal from '@/components/Navigation/roleFederal.vue';
import navLicencie from '@/components/Navigation/licencie.vue';

export default {
  name: 'Navigation',
  components: { navRoleFederal, navLicencie },

  data: () => ({
    currentYear: new Date().getFullYear(),
  }),

  computed: {
    ...mapGetters('app', ['appUserMode']),
    ...mapGetters('user', ['hasRoleFederalOn', 'currentUserId']),
    ...mapGetters('structure', ['currentStructureId']),
    ...mapGetters("saisons", ["currentSaison"]),

    hasRoleFederalNav() {
      return (
        this.hasRoleFederalOn(this.currentStructureId) &&
        this.appUserMode === 'federal'
      );
    },
  },
};
</script>

<style lang="scss">
.nav-main {
  a:hover {
    cursor: pointer !important;
  }
}
.app__nav-content nav ul .__home {
  a.__active::before {
    display: none;
  }
  a.__exact-active::before {
    display: block;
  }
}
.app__nav-content nav ul a.__exact-active::before,
.app__nav-content nav ul a:hover::before {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 5px;
  background: #00abe7;
  left: 0;
  top: 0;
}

.lemonway {
  width: 20px;
  img {
    width: 100%;
  }
}
</style>
